import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useParams,
  useLocation,
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import PublicShopApp from "../../containers/PublicShopApp";
import { reducers } from "../../store/configureShopStore";
import withReducers from "../../store/withReducers";
import NoMatch from "../NoMatch";
import { getQueryParams } from "../../utils";
import ErrorBoundary from "../ErrorBoundary";
import { useDispatch, useStore } from "react-redux";
import { fetchProductItems, setCart } from "../../actions/shop";
import { loadCartStateFromLocalStorage } from "../../utils/cartLocalStorage";
import { initializeProductQuestions } from "../../redux/hooks/product_questions";
import { setProductQuestionsData } from "../../redux/productQuestions";

const AppRoute = React.memo(({ panel }) => {
  const params = useParams();
  const { shop_id } = useParams();
  const location = useLocation();
  const queryParams = useMemo(() => getQueryParams({ location }), [location]);
  const store = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shop_id) return;

    const orders = store.getState().entities.orders;
    const itemColors = store.getState().entities.colors;
    const itemSkus = store.getState().entities.skus;
    const itemSizes = store.getState().entities.sizes;
    const items = store.getState().entities.items;
    const order_id = Object.keys(orders || {})?.[0] ?? "";
    const optionItems = Object.keys(items).filter((key) => items[key].parent_type === "OPTION");

    const cart = loadCartStateFromLocalStorage(
      shop_id,
      itemSkus,
      itemColors,
      itemSizes,
      optionItems
    );

    dispatch(setCart(cart));
    let questions = {};
    Object.keys(cart).length > 0 &&
      Object.keys(cart).forEach((item_id) => {
        const availableQuestions = initializeProductQuestions(shop_id, item_id);
        questions = { ...questions, ...availableQuestions };
        order_id && item_id && dispatch(fetchProductItems(item_id, order_id));
      });
      dispatch(setProductQuestionsData({ data: questions }));
  }, [shop_id]);

  if (panel) {
    return (
      <PublicShopApp
        panel={panel}
        params={{ ...queryParams, ...params }}
        location={location}
      />
    );
  }

  return <NoMatch location={location} />;
});

const PublicShopRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/shop/:shop_id"
        element={<AppRoute panel="home" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/shop"
        element={<AppRoute panel="shop" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/shop/:item_id"
        element={<AppRoute panel="shop" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/cart"
        element={<AppRoute panel="cart" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/checkout"
        element={<AppRoute panel="checkout" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path="/shop/:shop_id/receipt"
        element={<AppRoute panel="receipt" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default withReducers(PublicShopRouter, reducers, true);
